/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import {
	useFetchLovListForRefrence,
	useLoadOnlineDependentsList,
	useLoadOnlineMultiDependentsList,
	useFetchAutoPopulateLov,
} from '../../../../../hooks/form.hooks'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
	getMessageUniqueId,
	toSnakeCase,
} from '../../../../../utils/common.util'
import { loadFinalValue } from '../../../../../utils/form.util'
import Select from '../../../../chakra-react-select'

const OnlineList = ({
	field,
	fieldList,
	isDisabled = true,
	isRequired,
	onValidate,
	activity,
	fieldIndex,
	scrollToBottom = () => {},
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const { mutate: dependentListMutate } = useLoadOnlineDependentsList()
	const { mutate: dependentListMultiLovMutate } =
		useLoadOnlineMultiDependentsList()
	const { mutate: lovListMutate } = useFetchLovListForRefrence()
	const { mutate: loadAutopopulateLovList } = useFetchAutoPopulateLov()
	// const { mutate: lovDynamicListMutate } = useFetchLovDynamicList()

	const [selectedOnlineList, setSelectedOnlineList] = useState('')

	const [dependentList, setDependentList] = useState([])
	useEffect(() => {
		if (
			!!fields[field.field_id] &&
			!!fields[field.field_id].field_value &&
			!!fields[field.field_id].field_value.isFromEdit
		) {
			setSelectedOnlineList(fields[field.field_id].field_value)
			onValidate(true)
		}
	}, [fields[field.field_id]])

	const buildLovListParams = srchStr => {
		let txtToSrch = ''
		if (srchStr && srchStr !== '') {
			txtToSrch = srchStr
		}
		const { lov_restriction } = field.field_inline_data || {}
		const lov_type_id = lov_restriction?.lov_type_id
		return {
			workforce_id: 0,
			type_id: !!lov_type_id ? lov_type_id : 0,
			entity_id: [2018, 2021, 2020].includes(Number(lov_type_id))
				? !!activity
					? activity.activity_id
					: 0
				: 0,
			search_string: txtToSrch,
			flag: Number(lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
			sort_flag: 1,
			workflow_activity_id: !!activity ? activity.activity_id : 0,
			field_id: field?.field_id,
		}
	}

	// const buildDynamicLovListParams = srchStr => {
	//   return {
	//     field_id: field.field_id,
	//   }
	// }

	const buildLovDependentListParams = (flag, entity_id, srchStr, type_id) => {
		let txtToSrch = ''
		if (srchStr && srchStr !== '') {
			txtToSrch = srchStr
		}
		return {
			workforce_id: 0,
			type_id: type_id,
			entity_id: entity_id,
			search_string: txtToSrch,
			flag: flag,
		}
	}
	const inputLovSelectChange = option => {
		const srchStr = option
		getRestrictions(srchStr)
	}

	const handleLovChange = value => {
		setSelectedOnlineList(value)
		if (!!isRequired) {
			if (!!value) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, {
				...value,
				data_type_combo_id: value?.details?.lov_id,
			}),
		})
	}

	const buildLovAutoPopulateParams = () => {
		const { field_id, form_id, field_inline_data } = field

		let filedId = field_inline_data?.lov_restriction?.dependent_field_id
		let fieldDependet = field_inline_data?.lov_restriction?.flag_dependent
		let activityId
		if (!!filedId) {
			let field = formFields?.[form_id]?.[filedId]
			activityId = field?.field_value?.details?.activity_id
		} else if (
			!!fieldDependet &&
			fieldDependet === 3 &&
			!!activity &&
			activity.activity_id
		) {
			activityId = activity.activity_id
		}
		if (activityId) {
			return {
				form_id: form_id,
				field_id: field_id,
				dependent_workflow_activity_id: activityId,
			}
		}
	}

	const getRestrictions = useCallback(
		async srchStr => {
			const { lov_restriction } = field.field_inline_data || {}
			// console.log({ lov_restriction })
			if (!!lov_restriction && lov_restriction.flag_dependent) {
				const {
					lov_type_flag,
					dependent_field_id,
					dependent_field_ids,
					lov_type_id,
					field_auto_populate_enabled,
					flag_dependent,
				} = lov_restriction || {}
				let dependentField = !!dependent_field_id ? dependent_field_id : ''
				let entity_id =
					!!fields[dependentField] &&
					fields[dependentField].field_value &&
					fields[dependentField].field_value.details
						? fields[dependentField].field_value.details.lov_id
						: 0
				let entity_id_1 = 0
				let entity_id_2 = 0
				if (
					entity_id === 0 &&
					flag_dependent !== 2 &&
					!!fields[dependentField] &&
					fields[dependentField].field_value.isFromEdit
				) {
					let dep = fieldList.filter(f => f.field_id === dependentField)
					let { lov_restriction } = !!dep[0] ? dep[0]?.field_inline_data : {}
					let resp = await new Promise((resolve, reject) => {
						lovListMutate(
							{
								workforce_id: 0,
								type_id: !!lov_restriction ? lov_restriction?.lov_type_id : 0,
								entity_id: [2018, 2021, 2020].includes(
									Number(lov_restriction?.lov_type_id)
								)
									? !!activity
										? activity.activity_id
										: 0
									: 0,
								search_string:
									!!fields[dependentField] &&
									fields[dependentField].field_value.value,
								flag: Number(lov_restriction?.lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
								sort_flag: 1,
								workflow_activity_id: 0,
								field_id: field?.field_id,
							},
							{
								onSuccess: async res => {
									resolve(res.data.response)
								},
								onError: async err => {
									reject([])
								},
							}
						)
					})
					entity_id = !!resp && !!resp[0] ? resp[0].details.lov_id : 0
				} else if (entity_id === 0 && flag_dependent === 2) {
					let dependentFieldIds = !!dependent_field_ids
						? dependent_field_ids
						: []
					let dep = fieldList.filter(f => f.field_id === dependentFieldIds[0])
					let { lov_restriction } = !!dep[0] ? dep[0]?.field_inline_data : {}
					let resp1 = await new Promise((resolve, reject) => {
						lovListMutate(
							{
								workforce_id: 0,
								type_id: !!lov_restriction ? lov_restriction?.lov_type_id : 0,
								entity_id: [2018, 2021, 2020].includes(
									Number(lov_restriction?.lov_type_id)
								)
									? !!activity
										? activity.activity_id
										: 0
									: 0,
								search_string:
									!!fields[dependentFieldIds[0]] &&
									fields[dependentFieldIds[0]].field_value.value,
								flag: Number(lov_restriction?.lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
								sort_flag: 1,
								workflow_activity_id: 0,
								field_id: field?.field_id,
							},
							{
								onSuccess: async res => {
									resolve(res.data.response)
								},
								onError: async err => {
									reject([])
								},
							}
						)
					})
					entity_id_1 = !!resp1 && !!resp1[0] ? resp1[0].details.lov_id : 0
					let dep2 = fieldList.filter(f => f.field_id === dependentFieldIds[1])
					let { lov_restriction: lov_restriction2 } = !!dep2[0]
						? dep2[0]?.field_inline_data
						: {}
					let { lov_type_flag, lov_type_id } = !!lov_restriction2
						? lov_restriction2
						: {}
					let ser =
						!!fields[dependentFieldIds[1]] &&
						fields[dependentFieldIds[1]].field_value.value
					let resp2 = await new Promise((resolve, reject) => {
						dependentListMutate(
							{
								...buildLovDependentListParams(
									lov_type_flag,
									entity_id_1,
									ser,
									lov_type_id
								),
							},
							{
								onSuccess: async res => {
									resolve(res.data.response)
								},
							}
						)
					})
					let respD2 = resp2.filter(f => f.label === ser)
					entity_id_2 = !!resp2 && !!respD2[0] ? respD2[0].details.lov_id : 0
				}

				if (flag_dependent !== 2) {
					if (
						(lov_restriction && field_auto_populate_enabled === 1) ||
						(lov_restriction && flag_dependent === 3)
					) {
						loadAutopopulateLovList(
							{
								...buildLovAutoPopulateParams(),
							},
							{
								onSuccess: async data => {
									let formatData =
										!!data && Array.isArray(data)
											? data
													.map(d => {
														return {
															label: d.lov_name,
															value: d.lov_name,
															details: d,
														}
													})
													.filter(Boolean)
											: []
									setDependentList(formatData)
								},
							}
						)
					} else {
						dependentListMutate(
							{
								...buildLovDependentListParams(
									lov_type_flag,
									entity_id,
									srchStr,
									lov_type_id
								),
							},
							{
								onSuccess: async res => {
									setDependentList(res.data.response)
								},
							}
						)
					}
				} else if (field_auto_populate_enabled === 0 && flag_dependent === 2) {
					let dependentFieldIds = !!dependent_field_ids
						? dependent_field_ids
						: []
					let entIds = dependentFieldIds.map(i => {
						let dep_value =
							!!fields[i] &&
							!!fields[i].field_value &&
							!!fields[i].field_value.details
								? fields[i].field_value.details.lov_id
								: 0
						return dep_value
					})
					let ent_id_1 = !!entIds[0] ? entIds[0] : entity_id_1
					let ent_id_2 = !!entIds[1] ? entIds[1] : entity_id_2
					dependentListMultiLovMutate(
						{
							workforce_id: 0,
							type_id: lov_type_id,
							entity_id_1: ent_id_1,
							// entity_id_2: ent_id_2,
							entity_ids_2: `[${ent_id_1},${ent_id_2}]`,
							search_string: srchStr,
							flag: lov_type_flag,
						},
						{
							onSuccess: async res => {
								setDependentList(res.data.response)
							},
						}
					)
				}
			} else {
				lovListMutate(
					{
						...buildLovListParams(srchStr),
					},
					{
						onSuccess: async res => {
							if (res?.data?.response) setDependentList(res.data.response)
						},
					}
				)
			}
		},
		[
			field,
			fields,
			buildLovListParams,
			buildLovDependentListParams,
			setDependentList,
			lovListMutate,
			dependentListMutate,
			dependentListMultiLovMutate,
		]
	)

	useEffect(() => {
		getRestrictions('')
	}, [])

	return (
		<>
			<Select
				className={`form-input-item-${toSnakeCase(field.field_name)}`}
				isDisabled={isDisabled}
				onInputChange={e => {
					inputLovSelectChange(e)
					scrollToBottom('select-scroll')
				}}
				onChange={e => handleLovChange(e)}
				options={dependentList}
				value={selectedOnlineList}
				name='lov_search'
				placeholder={
					field.field_place_holder || `Search by ${field.field_name}`
				}
				isSearchable={true}
				//menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
				onFocus={e => scrollToBottom('select-scroll')}
			/>
		</>
	)
}

OnlineList.propTypes = {
	field: PropTypes.object.isRequired,
	fieldList: PropTypes.array.isRequired,
}

export default OnlineList
