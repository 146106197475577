/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { useLoadWorkflowRef } from '../../../../../hooks/form.hooks'
import { AsyncSelect } from '../../../../chakra-react-select'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
	getMessageUniqueId,
	toSnakeCase,
} from '../../../../../utils/common.util'
import { loadFinalValue } from '../../../../../utils/form.util'
import {
	useFetchLovListForRefrence,
	useLoadOnlineDependentsList,
	useFetchAutoPopulateLov,
	useLoadOnlineMultiDependentsList,
} from '../../../../../hooks/form.hooks'
import { useAuthContext } from '../../../../../context/auth.context'
import { Box, Divider, Text, useStyles } from '@chakra-ui/react'
import Select from '../../../../chakra-react-select'
/**
 * datatypeId 79
 * datatypename  Multi Workflow Reference
 */
const MultiChoiceLov = ({
	field,
	fieldList,
	isDisabled = true,
	isRequired,
	onValidate,
	activity,
	fieldIndex,
	scrollToBottom = () => {},
}) => {
	const { mutate, data } = useLoadWorkflowRef()
	const { mutate: dependentListMutate } = useLoadOnlineDependentsList()
	const { mutate: lovListMutate } = useFetchLovListForRefrence()
	const { mutate: loadAutopopulateLovList } = useFetchAutoPopulateLov()
	const { mutate: dependentListMultiLovMutate } =
		useLoadOnlineMultiDependentsList()
	const {
		field_id,
		form_id,
		field_reference_id,
		field_name,
		data_type_id,
		data_type_category_id,
		field_inline_data,
	} = field

	const [dependentList, setDependentList] = useState([])
	const [selectedOnlineList, setSelectedOnlineList] = useState([])

	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const {
		state: { authData },
	} = useAuthContext()

	const toReturnUpdatedValue = (value, selectedValue) => {
		let filterData =
			selectedValue?.filter(ele => ele?.details?.is_mandatory) || []
		let finalVal = filterData.concat(value)
		return finalVal.filter(
			(ele, i, elData) => elData.findIndex(val => val.label === ele.label) === i
		)
	}
	const handleLovChange = value => {
		let finalData = toReturnUpdatedValue(value, selectedOnlineList)
		setSelectedOnlineList(finalData)
		if (!!isRequired) {
			if (!!finalData) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			field_value: finalData,
			value: loadFinalValue(field, finalData),
		})
	}

	const buildLovListParams = (srchStr, page_start) => {
		let txtToSrch = ''
		if (srchStr && srchStr !== '') {
			txtToSrch = srchStr
		}
		const { lov_restriction } = field.field_inline_data || {}
		const lov_type_id = lov_restriction?.lov_type_id
		return {
			workforce_id: 0,
			type_id: !!lov_type_id ? lov_type_id : 0,
			entity_id: [2018, 2021, 2020].includes(Number(lov_type_id))
				? !!activity
					? activity.activity_id
					: 0
				: 0,
			search_string: txtToSrch,
			flag: Number(lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
			sort_flag: 1,
			workflow_activity_id: !!activity ? activity.activity_id : 0,
			field_id: field_id,
			page_start,
		}
	}
	const buildLovDependentListParams = (flag, entity_id, srchStr, type_id) => {
		let txtToSrch = ''
		if (srchStr && srchStr !== '') {
			txtToSrch = srchStr
		}
		return {
			workforce_id: 0,
			type_id: type_id,
			entity_id: entity_id,
			search_string: txtToSrch,
			flag: flag,
		}
	}

	const buildLovAutoPopulateParams = () => {
		let filedId = field_inline_data?.lov_restriction?.dependent_field_id
		let fieldDependet = field_inline_data?.lov_restriction?.flag_dependent
		let activityId
		if (!!filedId) {
			let field = formFields?.[form_id]?.[filedId]
			activityId = field?.field_value?.details?.activity_id
		} else if (
			!!fieldDependet &&
			fieldDependet === 3 &&
			!!activity &&
			activity.activity_id
		) {
			activityId = activity.activity_id
		}
		if (activityId) {
			return {
				form_id: form_id,
				field_id: field_id,
				dependent_workflow_activity_id: activityId,
			}
		}
	}

	const inputLovSelectChange = (search, page_start) => {
		getRestrictions(search, page_start)
	}

	const getRestrictions = useCallback(
		async (srchStr, page_start = 0) => {
			const { lov_restriction } = field.field_inline_data || {}
			if (!!lov_restriction && lov_restriction.flag_dependent) {
				const {
					lov_type_flag,
					dependent_field_id,
					dependent_field_ids,
					field_auto_populate_enabled,
					flag_dependent,
					lov_type_id,
				} = lov_restriction || {}
				let entity_id_1 = 0
				let entity_id_2 = 0
				let dependentField = !!dependent_field_id ? dependent_field_id : ''
				let entity_id =
					!!fields[dependentField] &&
					fields[dependentField].field_value &&
					fields[dependentField].field_value.details
						? fields[dependentField].field_value.details.lov_id
						: 0
				if (
					(lov_restriction && field_auto_populate_enabled === 1) ||
					(lov_restriction && flag_dependent === 3)
				) {
					loadAutopopulateLovList(
						{
							...buildLovAutoPopulateParams(),
						},
						{
							onSuccess: async data => {
								let formatData =
									!!data && Array.isArray(data)
										? data
												.map(d => {
													return {
														label: d.lov_name,
														value: d.lov_name,
														details: d,
													}
												})
												.filter(Boolean)
										: []
								setDependentList(formatData)
							},
						}
					)
				} else {
					if (
						entity_id === 0 &&
						flag_dependent !== 2 &&
						!!fields[dependentField] &&
						fields[dependentField].field_value.isFromEdit
					) {
						let dep = fieldList.filter(f => f.field_id === dependentField)
						let { lov_restriction } = !!dep[0] ? dep[0]?.field_inline_data : {}
						let resp = await new Promise((resolve, reject) => {
							lovListMutate(
								{
									workforce_id: 0,
									type_id: !!lov_restriction ? lov_restriction?.lov_type_id : 0,
									entity_id: [2018, 2021, 2020].includes(
										Number(lov_restriction?.lov_type_id)
									)
										? !!activity
											? activity.activity_id
											: 0
										: 0,
									search_string:
										!!fields[dependentField] &&
										fields[dependentField].field_value.value,
									flag: Number(lov_restriction?.lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
									sort_flag: 1,
									workflow_activity_id: 0,
									field_id: field_id,
								},
								{
									onSuccess: async res => {
										resolve(res.data.response)
									},
									onError: async err => {
										reject([])
									},
								}
							)
						})
						entity_id = !!resp && !!resp[0] ? resp[0].details.lov_id : 0
					} else if (entity_id === 0 && flag_dependent === 2) {
						let dependentFieldIds = !!dependent_field_ids
							? dependent_field_ids
							: []
						let dep = fieldList.filter(f => f.field_id === dependentFieldIds[0])
						let { lov_restriction } = !!dep[0] ? dep[0]?.field_inline_data : {}
						let resp1 = await new Promise((resolve, reject) => {
							lovListMutate(
								{
									workforce_id: 0,
									type_id: !!lov_restriction ? lov_restriction?.lov_type_id : 0,
									entity_id: [2018, 2021, 2020].includes(
										Number(lov_restriction?.lov_type_id)
									)
										? !!activity
											? activity.activity_id
											: 0
										: 0,
									search_string:
										!!fields[dependentFieldIds[0]] &&
										fields[dependentFieldIds[0]].field_value.value,
									flag: Number(lov_restriction?.lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
									sort_flag: 1,
									workflow_activity_id: 0,
									field_id: field_id,
								},
								{
									onSuccess: async res => {
										resolve(res.data.response)
									},
									onError: async err => {
										reject([])
									},
								}
							)
						})
						entity_id_1 = !!resp1 && !!resp1[0] ? resp1[0].details.lov_id : 0

						let dep2 = fieldList.filter(
							f => f.field_id === dependentFieldIds[1]
						)
						let { lov_restriction: lov_restriction2 } = !!dep2[0]
							? dep2[0]?.field_inline_data
							: {}
						let {
							lov_type_flag,
							lov_type_id,
							flag_dependent,
							dependent_field_id,
						} = !!lov_restriction2 ? lov_restriction2 : {}

						let ser = !!dependent_field_id
							? !!fields[dependent_field_id] &&
							  fields[dependent_field_id].field_value.value
							: !!fields[dependentFieldIds[1]] &&
							  fields[dependentFieldIds[1]].field_value.value
						if (flag_dependent === 1) {
							let dep23 = fieldList.filter(
								f => f.field_id === dependent_field_id
							)
							let { lov_restriction: lov_restriction23 } = !!dep23[0]
								? dep23[0]?.field_inline_data
								: {}
							let { lov_type_flag, lov_type_id, flag_dependent } =
								!!lov_restriction23 ? lov_restriction23 : {}
							let resp21 = []
							if (!flag_dependent) {
								resp21 = await new Promise((resolve, reject) => {
									lovListMutate(
										{
											workforce_id: 0,
											type_id: !!lov_restriction23
												? lov_restriction23?.lov_type_id
												: 0,
											entity_id: [2018, 2021, 2020].includes(
												Number(lov_restriction23?.lov_type_id)
											)
												? !!activity
													? activity.activity_id
													: 0
												: 0,
											search_string: ser,
											flag:
												Number(lov_restriction23?.lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
											sort_flag: 1,
											workflow_activity_id: 0,
											field_id: field_id,
										},
										{
											onSuccess: async res => {
												resolve(res.data.response)
											},
											onError: async err => {
												reject([])
											},
										}
									)
								})
							} else {
								resp21 = await new Promise((resolve, reject) => {
									dependentListMutate(
										{
											...buildLovDependentListParams(
												lov_type_flag,
												entity_id,
												ser,
												lov_type_id
											),
										},
										{
											onSuccess: async res => {
												resolve(res.data.response)
											},
										}
									)
								})
							}
							let respD21 = resp21.filter(f => f.label === ser)
							entity_id_2 =
								!!resp21 && !!respD21[0] ? respD21[0].details.lov_id : 0
						} else {
							let resp2 = await new Promise((resolve, reject) => {
								dependentListMutate(
									{
										...buildLovDependentListParams(
											lov_type_flag,
											entity_id_1,
											ser,
											lov_type_id
										),
									},
									{
										onSuccess: async res => {
											resolve(res.data.response)
										},
									}
								)
							})
							let respD2 = resp2.filter(f => f.label === ser)
							entity_id_2 =
								!!resp2 && !!respD2[0] ? respD2[0].details.lov_id : 0
						}
					}
					if (flag_dependent !== 2) {
						dependentListMutate(
							{
								...buildLovDependentListParams(
									lov_type_flag,
									entity_id,
									srchStr,
									lov_type_id
								),
							},
							{
								onSuccess: async res => {
									let fixedOpt = res?.data?.response.filter(
										val => val?.details?.is_mandatory
									)
									if (selectedOnlineList.length === 0 && fixedOpt.length > 0) {
										setSelectedOnlineList(fixedOpt)
										handleLovChange(fixedOpt)
										onValidate(true)
									}
									setDependentList(res.data.response)
								},
							}
						)
					} else if (
						field_auto_populate_enabled === 0 &&
						flag_dependent === 2
					) {
						let dependentFieldIds = !!dependent_field_ids
							? dependent_field_ids
							: []
						let entIds = dependentFieldIds.map(i => {
							let dep_value =
								!!fields[i] && !!fields[i].final
									? fields[i].final?.data_type_combo_id
									: 0
							return dep_value
						})
						let ent_id_1 = !!entIds[0] ? entIds[0] : entity_id_1
						let ent_id_2 = !!entIds[1] ? entIds[1] : entity_id_2
						dependentListMultiLovMutate(
							{
								workforce_id: 0,
								type_id: lov_type_id,
								entity_id_1: ent_id_1,
								// entity_id_2: ent_id_2,
								entity_ids_2: `[${ent_id_1},${ent_id_2}]`,
								search_string: srchStr,
								flag: lov_type_flag,
							},
							{
								onSuccess: async res => {
									let fixedOpt = res?.data?.response.filter(
										val => val?.details?.is_mandatory
									)
									if (selectedOnlineList.length === 0 && fixedOpt.length > 0) {
										setSelectedOnlineList(fixedOpt)
										handleLovChange(fixedOpt)
										onValidate(true)
									}
									setDependentList(res.data.response)
								},
							}
						)
					}
				}
			} else {
				lovListMutate(
					{
						...buildLovListParams(srchStr),
					},
					{
						onSuccess: async res => {
							let fixedOpt = res?.data?.response.filter(
								val => val?.details?.is_mandatory
							)
							if (selectedOnlineList.length === 0 && fixedOpt.length > 0) {
								setSelectedOnlineList(fixedOpt)
								handleLovChange(fixedOpt)
								onValidate(true)
							}
							setDependentList(res.data.response)
						},
					}
				)
			}
		},
		[
			field,
			fields,
			buildLovListParams,
			buildLovDependentListParams,
			setDependentList,
			lovListMutate,
			dependentListMutate,
		]
	)

	useEffect(() => {
		if (
			!!fields[field.field_id] &&
			!!fields[field.field_id].field_value.isFromEdit
		) {
			if (fields[field.field_id].field_value.finalArray.length > 0) {
				setSelectedOnlineList(fields[field.field_id].field_value.finalArray)
				handleLovChange(fields[field.field_id].field_value.finalArray)
				onValidate(true)
			}
		}
		getRestrictions('')
	}, [fields[field.field_id]])

	const styles = {
		multiValue: (base, state) => {
			return state.data.details.is_mandatory
				? { ...base, backgroundColor: 'gray' }
				: base
		},
		multiValueLabel: (base, state) => {
			return state.data.details.is_mandatory
				? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
				: base
		},
		multiValueRemove: (base, state) => {
			return state.data.details.is_mandatory
				? { ...base, display: 'none' }
				: base
		},
	}

	return (
		<Select
			className={`form-input-item-${toSnakeCase(field.field_name)}`}
			isDisabled={isDisabled}
			isMulti={true}
			isClearable={
				(selectedOnlineList || [])?.some(v => !v?.details?.is_mandatory) || []
			}
			styles={styles}
			onInputChange={e => {
				inputLovSelectChange(e)
				scrollToBottom('select-scroll')
			}}
			onChange={e => handleLovChange(e)}
			options={dependentList}
			value={selectedOnlineList}
			name='lov_search'
			placeholder={field.field_place_holder || `Search by ${field.field_name}`}
			isSearchable={true}
			onFocus={e => scrollToBottom('select-scroll')}
			//menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
		/>
	)
}

export default MultiChoiceLov
