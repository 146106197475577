import { Box, Divider, Flex, Text, useStyles, useTheme } from '@chakra-ui/react'
import { rag_status } from '../../../../../utils/common.util'
export const customAssetSelectFormat = {
	Option: ({
		innerRef,
		innerProps,
		children,
		isFocused,
		isDisabled,
		data,
		selectProps: { size },
	}) => {
		const theme = useTheme()
		const { item } = useStyles()
		// const {
		//   details: {
		//     asset_type_name: asset_first_name,
		//     operating_asset_phone_number,
		//   },
		// } = data
		const { field_rag_status_name = '' } = data
		return (
			<Box
				role='button'
				borderRadius='sm'
				sx={{
					...item,
					w: '100%',
					textAlign: 'left',
					bg: isFocused ? theme.colors.gray[100] : 'transparent',
					cursor: 'pointer',
					fontSize: size,
					...(isDisabled && item._disabled),
				}}
				ref={innerRef}
				{...innerProps}
				{...(isDisabled && { disabled: true })}
			>
				<Flex alignItems='center'>
					{field_rag_status_name ? (
						<Text
							height='10px'
							width='10px'
							borderRadius='50%'
							backgroundColor={
								rag_status[field_rag_status_name.toLowerCase()] || 'red'
							}
							marginRight='10px'
						></Text>
					) : null}
					{children}
				</Flex>
			</Box>
		)
	},
}
