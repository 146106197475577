/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
	getMessageUniqueId,
	toSnakeCase,
} from '../../../../../utils/common.util'
// import Select from 'react-select';
import Select from '../../../../chakra-react-select'
import { customAssetSelectFormat } from './optionFormat'
import { useAuthContext } from '../../../../../context/auth.context'

/**
 *
 *  dataTypeID : 33
 *  dataTypeName : Single Selection
 */

const SingleSelectionList = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	fieldIndex,
	scrollToBottom = () => {},
}) => {
	const {
		options,
		field_id,
		form_id,
		field_reference_id,
		field_name,
		data_type_id,
		data_type_category_id,
	} = field

	const {
		dispatch,
		state: { fields: formFields },
	} = useSmartFormContext()

	const {
		state: {
			authData: { organization_flag_enable_grid },
		},
	} = useAuthContext()

	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const onChange = (value, frm_input = false) => {
		if (!!isRequired) {
			if (!!value) {
				onValidate(!!value.value, value, frm_input)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true, value, frm_input)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id,
					field_id,
					field_reference_id,
					field_name,
					field_data_type_id: data_type_id,
					field_data_type_category_id: data_type_category_id,
					data_type_combo_id: value.data_type_combo_id,
					data_type_combo_value: 0,
					field_value: value.value,
					message_unique_id: getMessageUniqueId(),
				},
				field_value: value,
			},
		})
		dispatch({
			type: smartFormConst.TO_REFRESH_NUM_VAL,
		})
	}

	let defaultOption = {}
	useEffect(() => {
		let option = options.find(opt => opt.field_flag_is_default === 1)
		if (!!fields[field.field_id] && !!fields[field.field_id].field_value) {
			onValidate(
				!!fields[field.field_id].field_value?.value,
				fields[field.field_id].field_value
			)
		} else if (field && option) {
			defaultOption = {
				name: option.name,
				label: option.label,
				value: option.value,
				comboId: option.data_type_combo_id,
				comboNextFieldId: field.next_field_id,
				data_type_combo_id: option.data_type_combo_id,
				field_rag_status_name: field.field_rag_status_name,
				field_flag_is_default: option.field_flag_is_default,
			}
			//onValidate(true, defaultOption)
			onChange(defaultOption, true)
		} else {
			if (organization_flag_enable_grid !== 1) {
				onValidate(false, options[0])
				dispatch({
					type: smartFormConst.SET_FIELD_VALUE,
					fieldId: field.field_id,
					formId: field.form_id,
					value: {
						final: {
							form_id,
							field_id,
							field_reference_id,
							field_name,
							field_data_type_id: data_type_id,
							field_data_type_category_id: data_type_category_id,
							data_type_combo_id: options[0].data_type_combo_id,
							data_type_combo_value: 0,
							field_value: options[0].value,
							message_unique_id: getMessageUniqueId(),
						},
						field_value: options[0],
					},
				})
				onChange(options[0])
			}
		}
	}, [fields[field.field_id]])

	// TODO: fix it if occured broken somewhere
	let disableCondition =
		!!field.field_value_prefill_enabled &&
		field.field_value_prefill_enabled === 1 &&
		!!field.field_value_edit_enabled &&
		field.field_value_edit_enabled === 1
	if (field.field_value_prefill_enabled === 1) {
		disableCondition =
			field.field_inline_data?.field_flag_prefilled_data_enabled === 1
				? false
				: true
	}
	return (
		<Select
			className={`single-selection-list-${toSnakeCase(field.field_name)}  ${
				fields[field.field_id] ? fields[field.field_id].field_value : ''
			}`}
			isDisabled={isDisabled || disableCondition}
			id={field.field_name.split(' ').join('-')}
			options={options}
			// defaultOptions={options || []}
			loadOptions={options}
			components={customAssetSelectFormat}
			onChange={e => onChange(e, true)}
			placeholder={field.field_place_holder || `Select ${field.field_name}`}
			borderRadius='md'
			value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
			size='sm'
			maxMenuHeight='250'
			onInputChange={e => scrollToBottom('select-scroll')}
			onFocus={e => scrollToBottom('select-scroll')}
			//menuPlacement={fieldIndex > 1 ? 'top' : 'bottom'}
		/>
	)
}

export default SingleSelectionList
