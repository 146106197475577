/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import Select from '../../../../chakra-react-select';
import './style.scss';
import { VStack } from '@chakra-ui/react';
import { useSmartFormContext } from '../../../../../context/smart-form.context';
import { smartFormConst } from '../../../../../utils/action.constant';
import { loadFinalValue } from '../../../../../utils/form.util';
import { getMessageUniqueId } from '../../../../../utils/common.util';
import { debounce } from 'lodash';
import { useLoadCompositLovMultiList } from '../../../../../hooks/form.hooks';

const CompositLovField = ({
  field,
  isDisabled = true,
  onValidate,
  isRequired,
  fieldIndex,
  scrollToBottom = () => {},
}) => {
  const { field_id, field_inline_data } = field;

  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext();
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {};

  const { mutate: mutateLovList, data: lovList } =
    useLoadCompositLovMultiList();

  const [lovSelectedData, setLovSelectedData] = useState({});

  const { lov_restriction } = field_inline_data;
  let f_type_id =
    !!lov_restriction && !!lov_restriction[0]
      ? lov_restriction[0].lov_type_id
      : 0;

  const loadNextOptions = i => {
    let lov =
      !!lov_restriction && !!lov_restriction[i + 1]
        ? lov_restriction[i + 1]
        : null;
    if (!!lov) {
      onDebounceSearch('', lov, i);
    }
  };

  const onDebounceSearch = useCallback(
    debounce((text, lov, j) => {
      mutateLovList({
        search_string: text,
        type_id: lov.lov_type_id,
        entity_id: !!lovSelectedData[j] ? lovSelectedData[j].value : null,
      });
    }, 500)
  );
  useEffect(() => {
    mutateLovList({
      type_id: f_type_id,
      search_string: '',
    });
  }, []);
  useEffect(() => {
    if (
      !!fields[field_id] &&
      !!fields[field_id].field_value &&
      !!fields[field_id].field_value.isFromEdit
    ) {
      let editVal = fields[field_id].field_value.list;
      if (!!editVal) {
        let sParam = Object.assign({}, editVal);
        setLovSelectedData(sParam);
      }
    }
  }, [fields]);
  useEffect(() => {
    if (Object.keys(lovSelectedData).length) {
      if (!!isRequired) {
        if (lov_restriction.length === Object.keys(lovSelectedData).length) {
          onValidate(true);
        } else {
          onValidate(false);
        }
      } else {
        onValidate(true);
      }
      let compositevalue = Object.values(lovSelectedData).map(v => {
        if (Array.isArray(v)) {
          return v.map(d => {
            return {
              ...d.details,
              lov_type_id: d.lov_type_id,
              lov_type_name: d.lov_type_name,
              sequence_id: d.sequence_id,
              lov_multi_enabled: d.lov_multi_enabled,
            };
          });
        } else {
          return {
            ...v.details,
            lov_type_id: v.lov_type_id,
            lov_type_name: v.lov_type_name,
            sequence_id: v.sequence_id,
          };
        }
      });
      dispatch({
        type: smartFormConst.SET_FIELD_VALUE,
        fieldId: field.field_id,
        formId: field.form_id,
        value: loadFinalValue(field, compositevalue),
      });
    }
  }, [lovSelectedData]);
  return (
    <VStack spacing={2} w="full">
      {!!lov_restriction && Array.isArray(lov_restriction)
        ? lov_restriction.map((lov, i) => {
            return (
              <Select
                key={i}
                isMulti={
                  i === lov_restriction.length - 1 && !!lov.lov_multi_enabled
                }
                className="full-width"
                name={field.field_id}
                id={`${field.field_name.split(' ').join('-')}-state`}
                isDisabled={isDisabled}
                options={
                  !!lovList && !!lovList[lov.lov_type_id]
                    ? lovList[lov.lov_type_id]
                    : []
                }
                placeholder={`Select ${lov.lov_type_name}`}
                borderRadius="md"
                size="sm"
                onInputChange={e => {
                  onDebounceSearch(e, lov, i - 1);
                  scrollToBottom('select-scroll');
                }}
                value={lovSelectedData[i]}
                onChange={e => {
                  let saveParams = {
                    ...e,
                    ...lov,
                  };
                  if (
                    i === lov_restriction.length - 1 &&
                    !!lov.lov_multi_enabled
                  ) {
                    saveParams = e.map(s => ({ ...s, ...lov }));
                  }
                  setLovSelectedData(s => ({
                    ...s,
                    [i]: saveParams,
                  }));
                  loadNextOptions(i);
                }}
                //menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
                onFocus={e => scrollToBottom('select-scroll')}
              />
            );
          })
        : null}
    </VStack>
  );
};

export default CompositLovField;
